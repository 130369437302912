/* eslint-disable no-unused-vars */
import React from 'react';
import Footer from '../components/Footer';
import CustomInputy from '../components/CustomInputTwo';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Meta from '../components/Meta';
import SpinerComponent from '../components/SpinerComponent';
import { useGetVendorsQuery } from '../features/vendors/vendorsApiSlice';
import { useUpdateVendorMutation } from '../features/vendors/vendorsApiSlice';
import { Navbar } from '../components/Navbar';

let schema = Yup.object().shape({
  business_momo_name: Yup.string().required('Business MOMO name is required'),
  business_momo_mobile: Yup.string().required(
    'Business MOMO number is required'
  ),
});

const SignupPageThree = () => {
  const navigate = useNavigate();
  const [showSpina, setShowSpina] = useState(false);
  const [showErr, setShowErr] = useState(false);

  const { id } = useParams();
  localStorage.setItem('vid', id);

  const { vendor } = useGetVendorsQuery('vendorsList', {
    selectFromResult: ({ data }) => ({
      vendor: data?.entities[id],
    }),
  });

  const [updateVendor, { isLoading, isSuccess, error }] =
    useUpdateVendorMutation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const sendEmailToVendor = async () => {
    try {
      const BusinessName = vendor?.BusinessName;
      const email = vendor?.email;
      const formData = new FormData();
      formData.append('BusinessName', BusinessName);
      formData.append('email', email);
      const urlEncoded = new URLSearchParams(formData).toString();
      await fetch(
        'https://worknshup-vendor-backend-0ea3e2e25f6c.herokuapp.com/api/vendor/send-email',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
          body: urlEncoded,
        }
      ).catch((err) => console.log(`Error occured: ${err}`));
    } catch (err) {
      console.log('error: email not sent!');
    }
  };

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }

    if (isSuccess) {
      sendEmailToVendor();
      setShowSpina(false);
      navigate(`/vendor-approval-page`);
    }
    if (error) {
      setShowSpina(false);
      setShowErr(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, error]);

  const formik = useFormik({
    initialValues: {
      business_momo_name: '',
      business_momo_mobile: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const { business_momo_name, business_momo_mobile } = values;

      const momo_mobile_name = business_momo_name;
      const momo_mobile = business_momo_mobile;
      const registrationCompleted = true;
      const id = vendor._id;

      try {
        await updateVendor({
          id,
          momo_mobile_name,
          momo_mobile,
          registrationCompleted,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  });

  return (
    <>
      <Meta title="Sign-Up-Three" />
      <Navbar />

      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid py-1 ">
          <div className="row">
            <div className="col-12">
              <form
                id="signtwoform"
                className="d-flex flex-column align-items-center gap-10 pb-5"
                action=""
                onSubmit={formik.handleSubmit}
              >
                <h2 className="pb-5">Payment details</h2>
                <label htmlFor="business_name">Business MOMO Name</label>
                <CustomInputy
                  type="text"
                  name="business_momo_name"
                  id="business_momo_name"
                  val={formik.values.business_name}
                  onCh={formik.handleChange('business_momo_name')}
                />
                <div className="error">
                  {formik.touched.business_momo_name &&
                  formik.errors.business_momo_name ? (
                    <div>{formik.errors.business_momo_name}</div>
                  ) : null}
                </div>

                <label htmlFor="business_mobile">Business MOMO number</label>
                <CustomInputy
                  type="text"
                  name="business_momo_mobile"
                  id="business_momo_mobile"
                  val={formik.values.business_momo_mobile}
                  onCh={formik.handleChange('business_momo_mobile')}
                />
                <div className="error">
                  {formik.touched.business_momo_mobile &&
                  formik.errors.business_momo_mobile ? (
                    <div>{formik.errors.business_momo_mobile}</div>
                  ) : null}
                </div>

                <button
                  className="signupbtn border-0 px-3 py-2 text-white fw-bold text-center  mt-5 fs-5"
                  type="submit"
                >
                  next
                </button>
                {showSpina && <SpinerComponent />}
                {showErr && (
                  <p style={{ color: 'red' }}>Something went wrong!</p>
                )}
              </form>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default SignupPageThree;
