import React, { useEffect, useState } from 'react';
import CustomInputy from '../components/CustomInputTwo';
import { useParams } from 'react-router-dom';
import { Select } from 'antd';
import SpinerComponent from '../components/SpinerComponent';
import { useNavigate } from 'react-router-dom';
import Meta from '../components/Meta';
import { Navbar } from '../components/Navbar';
import Footer from '../components/Footer';
import { toast } from 'react-toastify';
import { useUpdateVendorMutation } from '../features/vendors/vendorsApiSlice';
import { useGetVendorsQuery } from '../features/vendors/vendorsApiSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';

let schema = Yup.object().shape({
  password: Yup.string(),
  BusinessName: Yup.string(),
  mobile: Yup.string(),
  momo_mobile: Yup.string(),
  momo_mobile_name: Yup.string(),
  email: Yup.string(),
  BusinessCategory: Yup.string(),
  confirm_password: Yup.string()
    .label('confirm password')
    .required('please confirm password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const EditVendorPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showSpina, setShowSpina] = useState(false);
  const { vendor } = useGetVendorsQuery('vendorsList', {
    selectFromResult: ({ data, isLoading, isSuccess }) => ({
      vendor: data?.entities[id],
      isLoading,
      isSuccess,
    }),
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [
    updateVendor,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      error: updateError,
    },
  ] = useUpdateVendorMutation();

  useEffect(() => {
    if (isUpdateLoading) {
      setShowSpina(true);
    }

    if (updateError) {
      setShowSpina(false);
      toast.error('Something went wrong');
    }

    if (isUpdateSuccess) {
      setShowSpina(false);
      setTimeout(() => {
        setShowSpina(false);
      }, 2000);
      navigate('/vendor-main-page');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateLoading, isUpdateSuccess, updateError]);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
      BusinessName: vendor?.BusinessName,
      mobile: vendor?.mobile,
      email: vendor?.email,
      momo_mobile: vendor?.momo_mobile,
      momo_mobile_name: vendor?.momo_mobile_name,
      BusinessCategory: vendor?.BusinessCategory,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const {
        password,
        BusinessName,
        email,
        mobile,
        momo_mobile,
        momo_mobile_name,
        BusinessCategory,
      } = values;

      try {
        await updateVendor({
          BusinessName,
          email,
          password,
          mobile,
          momo_mobile,
          momo_mobile_name,
          BusinessCategory,
          id,
        });
      } catch (error) {
        setTimeout(() => {
          setShowSpina(false);
        }, 2000);
      }
    },
  });

  return (
    <>
      <Meta title="Edit-Vendor-page" />
      <Navbar />
      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid py-1 ">
          <div className="row">
            <div className="col-12">
              <form
                className="d-flex  flex-column align-items-center"
                id="form"
                onSubmit={formik.handleSubmit}
              >
                <h3 className="mb-4 title">Edit Vendor Details</h3>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                  >
                    Business Name
                  </p>
                  <CustomInputy
                    type="text"
                    label="Enter Vendor Business Name"
                    name="title"
                    val={formik.values.BusinessName}
                    onCh={formik.handleChange('BusinessName')}
                  />
                </div>

                <div className="mt-3">
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    email
                  </p>
                  <CustomInputy
                    type="text"
                    label="Enter Vendor Email"
                    name="email"
                    val={formik.values.email}
                    onCh={formik.handleChange('email')}
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    Password
                  </p>
                  <CustomInputy
                    type="password"
                    label="Enter New Password"
                    name="password"
                    val={formik.values.password}
                    onCh={formik.handleChange('password')}
                  />

                  <CustomInputy
                    type="password"
                    name="confirm_password"
                    label="confirm Password"
                    id="confirm_pass"
                    val={formik.values.confirm_password}
                    onCh={formik.handleChange('confirm_password')}
                  />
                  <div className="error">
                    {formik.touched.confirm_password &&
                    formik.errors.confirm_password ? (
                      <div>{formik.errors.confirm_password}</div>
                    ) : null}
                  </div>
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    Business Mobile
                  </p>

                  <CustomInputy
                    type="text"
                    label="Enter Business Mobile No"
                    name="mobile"
                    val={formik.values.mobile}
                    onCh={formik.handleChange('mobile')}
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    MOMO number
                  </p>

                  <CustomInputy
                    type="text"
                    label="Enter Business MOMO Number"
                    name="momo_mobile"
                    val={formik.values.momo_mobile}
                    onCh={formik.handleChange('momo_mobile')}
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    MOMO Number Name
                  </p>
                  <CustomInputy
                    type="text"
                    name="momo_mobile_name"
                    val={formik.values.momo_mobile_name}
                    onCh={formik.handleChange('momo_mobile_name')}
                    label="Enter MOMO Name"
                    className="mb-3"
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    choose category
                  </p>

                  <Select
                    className="select-drop-down"
                    showSearch
                    val={formik.values.BusinessCategory}
                    placeholder="Select Category"
                    optionFilterProp="children"
                    onChange={formik.handleChange('category')}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: 'Phones and Tablets',
                        label: 'Phones and Tablets',
                      },
                      {
                        value: 'Computers and accessories',
                        label: 'Computers and accessories',
                      },
                      {
                        value: 'Fashion and wears',
                        label: 'Fashion and wears',
                      },
                      {
                        value: 'Home and Office',
                        label: 'Home and Office',
                      },
                      {
                        value: 'Electronic Appliances',
                        label: 'Electronic Appliances',
                      },
                      {
                        value: 'Food Market',
                        label: 'Food Market',
                      },
                    ]}
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-success border-0 rounded-3 mt-5"
                    style={{ marginRight: '5rem' }}
                  >
                    Update
                  </button>
                </div>

                {showSpina && <SpinerComponent />}
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default EditVendorPage;
