import React from 'react';

const SixMonthsPlan = ({ prodctPrice }) => {
  return (
    <div>
      <div>
        <h6>6 Months plan</h6>
        <div className="d-flex">
          <p>Initial Payment:</p>
          <p>GHS {Math.round(prodctPrice * 0.25)}</p>
        </div>
        <div className="d-flex">
          <p>Next Payment:</p>
          <p>GHS {Math.round(prodctPrice * 0.15)}</p>
        </div>
        <div className="d-flex">
          <p>Next Payment:</p>
          <p>GHS {Math.round(prodctPrice * 0.15)}</p>
        </div>
        <div className="d-flex">
          <p>Next Payment:</p>
          <p>GHS {Math.round(prodctPrice * 0.15)}</p>
        </div>
        <div className="d-flex">
          <p>Next Payment:</p>
          <p>GHS {Math.round(prodctPrice * 0.15)}</p>
        </div>
        <div className="d-flex">
          <p>Next Payment:</p>
          <p>GHS {Math.round(prodctPrice * 0.15)}</p>
        </div>
      </div>
    </div>
  );
};

export default SixMonthsPlan;
