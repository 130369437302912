import React from 'react';

const TwoMonthsPlan = ({ prodctPrice }) => {
  return (
    <div>
      <div>
        <h6>2 Months plan</h6>
        <div className="d-flex">
          <p>Initial Payment:</p>
          <p>GHS {Math.round(prodctPrice * 0.55)}</p>
        </div>
        <div className="d-flex">
          <p>Next Payment:</p>
          <p>GHS {Math.round(prodctPrice * 0.45)}</p>
        </div>
      </div>
    </div>
  );
};

export default TwoMonthsPlan;
