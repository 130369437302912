/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Meta from '../components/Meta';
import BreadCrumb from '../components/BreadCrumb';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useForgotPasswordMutation } from '../features/auth/authApiSlice';
import { toast } from 'react-toastify';
import SpinerComponent from '../components/SpinerComponent';
import CustomInputy from '../components/CustomInputTwo';

const schema = yup.object({
  email: yup
    .string()
    .email('Email should be valid')
    .required('Email is required!'),
});

const ForgotPassword = () => {
  // eslint-disable-next-line no-unused-vars
  const [showLoginError, setShowLoginError] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [showSpina, setShowSpina] = useState(false);
  const [ForgotPassword, { isLoading, isSuccess, error }] =
    useForgotPasswordMutation();

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }
    if (isSuccess) {
      setShowSpina(false);
      toast.success('Please check your Mail!');
      // navigate(-1);
    }

    if (error) {
      setShowSpina(false);
      setShowLoginError(true);
      setLoginError(error?.data?.msg);
      toast.error('Something went wrong!');
    }
  }, [isSuccess, isLoading, error]);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const { email } = values;

      try {
        await ForgotPassword({ email });
        //console.log(data);
        // dispatch(setCredentials(data));
      } catch (error) {
        throw new Error(error);
      }
    },
  });
  return (
    <>
      <Meta title="Forgot Password" />
      <BreadCrumb title="Forgot Password" />
      <div className="container-fluid">
        <div className="py-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="">
                  <h3 className="text-center mb-3">Reset Your Password</h3>
                  <p className="text-center mt-2 mb-3 my-2">
                    We will send a link to your email to reset password
                  </p>
                  <form
                    action=""
                    className="d-flex flex-column align-items-center gap-15"
                    onSubmit={formik.handleSubmit}
                  >
                    <div>
                      <CustomInputy
                        type="email"
                        name="email"
                        label="email"
                        className="forgot-password-input"
                        val={formik.values.email}
                        onCh={formik.handleChange('email')}
                        onBl={formik.handleBlur('email')}
                        style={{ color: 'black' }}
                      />
                    </div>
                    <div className="error">
                      {formik.touched.email && formik.errors.email}
                    </div>

                    {showLoginError && (
                      <div>
                        <p style={{ color: 'red' }}>
                          {loginError
                            ? loginError
                            : ' Invalid Email and password details.'}
                        </p>
                      </div>
                    )}

                    <div>
                      <div className="d-flex flex-column mt-3 justify-content-center align-items-center gap-15">
                        <button className="button border-0" type="submit">
                          Submit
                        </button>
                        <Link style={{ textDecoration: 'none' }} to="/">
                          Cancel
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="d-flex flex-column text-black">
                        <Link
                          style={{
                            textDecoration: 'none',
                            fontSize: '0.8rem',
                          }}
                          className="text-red pt-2"
                          to="/signup-one"
                        >
                          New User? Signup
                        </Link>
                      </div>
                    </div>
                    {showSpina && <SpinerComponent />}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
