import React, { useEffect } from 'react';
import { useGetProductsQuery } from '../features/products/productsApiSlice';
import { useNavigate, Link } from 'react-router-dom';

const VendorProducts = () => {
  const vndor = JSON.parse(localStorage.getItem('vendor'));
  const vndorid = localStorage.getItem('vdmail');
  const { data: products = [], isSuccess } = useGetProductsQuery(
    'productList',
    {
      pollingInterval: 15000,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const navigate = useNavigate();
  const { ids, entities } = products;
  const vndrPrdts = ids?.filter((ide) => {
    return entities[ide]?.vendorEmail === vndorid;
  });

  useEffect(() => {
    if (vndor === null) {
      navigate('/');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (vndor === null) {
      navigate('/');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vndor]);

  let content = (
    <div>
      <div className="contact-inner-wrapper d-flex justify-content-between">
        <div>
          <h3 className="contact-title mb-4">Products</h3>
        </div>
      </div>
    </div>
  );

  try {
    if (isSuccess) {
      content = (
        <div>
          <div className="container-fluid contact-inner-wrapper d-flex flex-column justify-content-between">
            <div>
              <h3 className="contact-title mb-4">Products</h3>
            </div>
            <div className="d-flex flex-column">
              <div className="row  w-100">
                {vndrPrdts?.map((idy) => {
                  return (
                    <div className="col-12">
                      <Link
                        to={`/edit-product/${entities[idy]._id}`}
                        className="d-flex align-items-end"
                        style={{
                          borderRadius: '0.5rem',
                          textDecoration: 'none',
                          backgroundColor: 'white',
                          marginBottom: '0.5rem',
                          padding: '0.5rem 0.5rem 0.5rem 0.5rem',
                        }}
                      >
                        <img
                          style={{ width: '50px', height: '50px' }}
                          src={entities[idy]?.images[0]?.url}
                          alt="product pic"
                        />
                        <div className="vendr-prdt-item1">
                          {entities[idy]?.title}
                        </div>
                        <div className="vendr-prdt-item" id="vendr-prdt-item4">
                          GHC {entities[idy]?.price}
                        </div>
                        <div className="vendr-prdt-item" id="vendr-prdt-item3">
                          {entities[idy]?.category}
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  } catch (err) {
    content = <p>Something went wrong!</p>;
  }

  return content;
};

export default VendorProducts;
