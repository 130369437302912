import React from 'react';
import { Link } from 'react-router-dom';

const VendorAccountDetails = ({ vendor }) => {
  return (
    <>
      <div className="container-fluid">
        <div className="border-bottom w-100">
          <h3 className="contact-title mb-4">Account Overview</h3>
        </div>
        <div className="row my-2">
          <div className="col-4 my-2 mx-1 acc-det1">
            <h6>Account Business Name:</h6>
            <p className="">{vendor?.BusinessName}</p>
          </div>
          <div className="col-4 my-2 mx-1 acc-det1">
            <h6>Account Password:</h6>
            <p className="">*******</p>
          </div>
          <div className="col-4 my-2 mx-1 acc-det1">
            <h6>Account Email:</h6>
            <p className="">{vendor?.email}</p>
          </div>
        </div>
        <div className="row my-2 ">
          <div className="col-4 my-2 mx-1 acc-det1">
            <h6>Business Phone Number:</h6>
            <p className="">{vendor?.mobile}</p>
          </div>
          <div className="col-4 my-2 mx-1 acc-det1">
            <h6>Business Mobile Money Number:</h6>
            <p className="">{vendor?.momo_mobile}</p>
          </div>
          <div className="col-4 my-2 mx-1 acc-det1">
            <h6>Business Mobile Money Name:</h6>
            <p className="">{vendor?.momo_mobile_name}</p>
          </div>
        </div>
        <div>
          <Link to={`/edit-vendor/${vendor?._id}`}>edit</Link>
        </div>
      </div>
    </>
  );
};

export default VendorAccountDetails;
