import React from 'react';

const PayDayPlan = ({ prodctPrice }) => {
  return (
    <div>
      <div>
        <h6>Pay on Payday plan</h6>
        <div className="d-flex">
          <p>Next Payment:</p>
          <p>GHS {prodctPrice}</p>
        </div>
      </div>
    </div>
  );
};

export default PayDayPlan;
