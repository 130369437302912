/* eslint-disable no-unused-vars */
import React from 'react';
import Meta from '../components/Meta';
import Footer from '../components/Footer';
import { Navbar } from '../components/Navbar';
import { Link } from 'react-router-dom';
import { useGetVendorsQuery } from '../features/vendors/vendorsApiSlice';

const IncompleteSignUpPage = () => {
  const email = localStorage.getItem('vdmail');
  const id = localStorage.getItem('vid');
  let idx = null;

  const {
    data,
    isSuccess: VendorSuc,
    isLoading: VendorLoad,
    error: VendorErr,
  } = useGetVendorsQuery('VendorList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { Vendor } = useGetVendorsQuery('VendorsList', {
    selectFromResult: ({ data }) => ({
      Vendor: data?.entities[id],
    }),
  });

  if (VendorSuc) {
    const { ids, entities } = data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    idx = ids.find((idx) => entities[idx]?.email === email);
  }

  return (
    <>
      <Meta title="Sign-Up-Two" />
      <Navbar />

      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid py-1 ">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <h2 className="pb-3">Please Complete Your Registration</h2>

              <Link to={`/signup-page-two/${idx}`}>Complete Registration</Link>
            </div>
          </div>
          <br />
          <br />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default IncompleteSignUpPage;
