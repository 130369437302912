import React from 'react';
import Footer from '../components/Footer';
import CustomInputy from '../components/CustomInputTwo';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Meta from '../components/Meta';
import { Select } from 'antd';
import SpinerComponent from '../components/SpinerComponent';
import { useGetVendorsQuery } from '../features/vendors/vendorsApiSlice';
import { useUpdateVendorMutation } from '../features/vendors/vendorsApiSlice';
import { Navbar } from '../components/Navbar';

let schema = Yup.object().shape({
  business_name: Yup.string().required('Business Name is required'),
  business_category: Yup.string().required('Business category is required'),
  business_address: Yup.string().required('Business address is required'),
  business_mobile: Yup.string().required('Business mobile number is required'),
});

const SignupPageTwo = () => {
  const navigate = useNavigate();
  const [showSpina, setShowSpina] = useState(false);
  const [showErr, setShowErr] = useState(false);

  const { id } = useParams();

  const { vendor } = useGetVendorsQuery('vendorsList', {
    selectFromResult: ({ data }) => ({
      vendor: data?.entities[id],
    }),
  });

  const [updateVendor, { isLoading, isSuccess, error }] =
    useUpdateVendorMutation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }

    if (isSuccess) {
      setShowSpina(false);
      navigate(`/signup-page-three/${vendor?._id}`);
    }
    if (error) {
      setShowSpina(false);
      setShowErr(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, error]);

  const formik = useFormik({
    initialValues: {
      business_name: '',
      business_category: '',
      business_address: '',
      business_mobile: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const {
        business_name,
        business_category,
        business_address,
        business_mobile,
      } = values;

      const BusinessName = business_name;
      const BusinessCategory = business_category;
      const BusinessAddress = business_address;
      const mobile = business_mobile;
      const id = vendor._id;

      try {
        await updateVendor({
          id,
          BusinessName,
          BusinessCategory,
          BusinessAddress,
          mobile,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  });

  return (
    <>
      <Meta title="Sign-Up-Two" />
      <Navbar />

      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid py-1 ">
          <div className="row">
            <div className="col-12">
              <form
                id="signtwoform"
                className="d-flex flex-column align-items-center gap-10 pb-5"
                action=""
                onSubmit={formik.handleSubmit}
              >
                <h2 className="pb-5">Business details</h2>
                <label htmlFor="business_name">Business Name</label>
                <CustomInputy
                  type="text"
                  name="business_name"
                  id="business_name"
                  val={formik.values.business_name}
                  onCh={formik.handleChange('business_name')}
                />
                <div className="error">
                  {formik.touched.business_name &&
                  formik.errors.business_name ? (
                    <div>{formik.errors.business_name}</div>
                  ) : null}
                </div>
                <label htmlFor="business_category">Business Category</label>
                <Select
                  className="bizcat"
                  showSearch
                  placeholder="Select Category"
                  optionFilterProp="children"
                  onChange={formik.handleChange('business_category')}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'Phones and Tablets',
                      label: 'Phones and Tablets',
                    },
                    {
                      value: 'Computers and accessories',
                      label: 'Computers and accessories',
                    },
                    {
                      value: 'Fashion and wears',
                      label: 'Fashion and wears',
                    },
                    {
                      value: 'Home and Office',
                      label: 'Home and Office',
                    },
                    {
                      value: 'Electronic Appliances',
                      label: 'Electronic Appliances',
                    },
                    {
                      value: 'Food Market',
                      label: 'Food Market',
                    },
                  ]}
                />
                <div className="error">
                  {formik.touched.business_category &&
                    formik.errors.business_category}
                </div>
                <label htmlFor="business_address">Business address</label>
                <CustomInputy
                  type="text"
                  name="business_address"
                  id="business_address"
                  val={formik.values.business_address}
                  onCh={formik.handleChange('business_address')}
                />
                <div className="error">
                  {formik.touched.business_address &&
                  formik.errors.business_address ? (
                    <div>{formik.errors.business_address}</div>
                  ) : null}
                </div>
                <label htmlFor="business_mobile">Business Phone number</label>
                <CustomInputy
                  type="text"
                  name="business_mobile"
                  id="business_mobile"
                  val={formik.values.business_mobile}
                  onCh={formik.handleChange('business_mobile')}
                />
                <div className="error">
                  {formik.touched.business_mobile &&
                  formik.errors.business_mobile ? (
                    <div>{formik.errors.business_mobile}</div>
                  ) : null}
                </div>

                <button
                  className="signupbtn border-0 px-3 py-2 text-white fw-bold text-center  mt-5 fs-5"
                  type="submit"
                >
                  next
                </button>
                {showSpina && <SpinerComponent />}
                {showErr && (
                  <p style={{ color: 'red' }}>Something went wrong!</p>
                )}
              </form>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default SignupPageTwo;
