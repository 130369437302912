import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: { token: null, vendor: null },
  reducers: {
    setCredentials: (state, action) => {
      state.token = JSON.stringify(action.payload.accessToken);
      state.vendor = JSON.stringify(action.payload);

      if (state.token) {
        localStorage.setItem('vendor_tkn', state.token);
        localStorage.setItem('vendor', state.vendor);
      }
    },
    logOut: (state, action) => {
      state.token = null;
      state.vendor = null;
      localStorage.setItem('vendor_tkn', null);
      localStorage.setItem('vendor', null);
      localStorage.setItem('vdmail', null);
      localStorage.setItem('vid', null);
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentvendor = (state) => state.auth.vendor;
