/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import { IoPersonSharp } from 'react-icons/io5';
import { useNavigate, Link } from 'react-router-dom';
import Meta from '../components/Meta';
import 'react-quill/dist/quill.snow.css';
import AddProductForm from '../components/AddProductForm';
import AddProductFormTwo from '../components/AddProductFormTwo';
import VendorProducts from '../components/VendorProducts';
import VendorAccountDetails from '../components/VendorAccountDetails';
import VendorOrders from '../components/VendorOrders';
import { useGetVendorsQuery } from '../features/vendors/vendorsApiSlice';
import { Navbar } from '../components/Navbar';

const MainPage = () => {
  const vndor = JSON.parse(localStorage.getItem('vendor'));
  const email = localStorage.getItem('vdmail');
  const id = localStorage.getItem('vid');
  const [showVendorProductForm, setShowVendorProductForm] = useState(true);
  const [showVendorAccDetails, setShowVendorAccDetails] = useState(false);
  const [showVendorOrders, setShowVendorOrders] = useState(false);
  const [showFormTwo, setShowFormTwo] = useState(false);
  const [showFormOne, setShowFormOne] = useState(false);
  const navigate = useNavigate();

  let idx = null;

  const { vendor } = useGetVendorsQuery('vendorsList', {
    selectFromResult: ({ data }) => ({
      vendor: data?.entities[id],
    }),
  });

  const {
    data,
    isSuccess: vendorSuc,
    isLoading: vendorLoad,
    error: vendorErr,
  } = useGetVendorsQuery('vendorList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  if (vendorSuc) {
    const { ids, entities } = data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //eslint-disable-next-line react-hooks/exhaustive-deps
    idx = ids.find((idx) => entities[idx]?.email === email);
    if (!entities[idx]?.registrationCompleted) {
      navigate('/incomplete-reg');
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (vndor === null) {
      navigate('/');
    }
    setShowVendorProductForm(true);
    setShowFormTwo(false);
    setShowFormOne(false);
    setShowVendorAccDetails(false);
    setShowVendorOrders(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vndor === null) {
      navigate('/');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vndor]);

  const handleShowNewPrdtcs = () => {
    setShowFormOne(true);
    setShowFormTwo(false);
    setShowVendorProductForm(false);
    setShowVendorAccDetails(false);
    setShowVendorOrders(false);
  };
  const handleShowAllPrdtcs = () => {
    setShowVendorProductForm(true);
    setShowFormOne(false);
    setShowFormTwo(false);
    setShowVendorAccDetails(false);
    setShowVendorOrders(false);
  };
  const handleShowVendorAccDetails = () => {
    setShowVendorAccDetails(true);
    setShowVendorProductForm(false);
    setShowFormOne(false);
    setShowFormTwo(false);
    setShowVendorOrders(false);
  };
  const handleShowVendorOrders = () => {
    setShowVendorOrders(true);
    setShowVendorAccDetails(false);
    setShowVendorProductForm(false);
    setShowFormOne(false);
    setShowFormTwo(false);
  };

  return (
    <>
      <Meta title="Vendor Page" />
      <Navbar
        handleShowVendorAccDetails={handleShowVendorAccDetails}
        handleShowAllPrdtcs={handleShowAllPrdtcs}
        handleShowNewPrdtcs={handleShowNewPrdtcs}
        handleShowVendorOrders={handleShowVendorOrders}
      />

      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid py-1 ">
          <div className="row">
            <div className="col-3 colapsy">
              <div className="contact-inner d-flex flex-column">
                <Link
                  onClick={() => handleShowAllPrdtcs()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className=" useracc1 w-100 py-2">
                    <IoPersonSharp
                      className="fs-1"
                      style={{
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        padding: '0.2rem',
                      }}
                    />
                    <p className=" mt-1">All Products</p>
                  </div>
                </Link>
                <Link
                  onClick={() => handleShowNewPrdtcs()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="py-2 useracc2 w-100">
                    <IoPersonSharp
                      className="fs-1"
                      style={{
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        padding: '0.2rem',
                      }}
                    />
                    <p>Add New Products</p>
                  </div>
                </Link>

                <Link
                  onClick={() => handleShowVendorOrders()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className=" useracc2 w-100 py-2">
                    <IoPersonSharp
                      className="fs-1"
                      style={{
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        padding: '0.2rem',
                      }}
                    />
                    <p>Your Orders</p>
                  </div>
                </Link>
                <Link
                  onClick={() => handleShowVendorAccDetails()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="py-2 useracc2 w-100">
                    <IoPersonSharp
                      className="fs-1"
                      style={{
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        padding: '0.2rem',
                      }}
                    />
                    <p>Profile</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-9 strechy">
              {showVendorProductForm && <VendorProducts />}
              {showFormTwo && (
                <AddProductFormTwo
                  setShowFormOne={setShowFormOne}
                  setShowFormTwo={setShowFormTwo}
                />
              )}
              {showFormOne && (
                <AddProductForm
                  setShowFormOne={setShowFormOne}
                  setShowFormTwo={setShowFormTwo}
                />
              )}
              {showVendorAccDetails && <VendorAccountDetails vendor={vendor} />}
              {showVendorOrders && <VendorOrders />}
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default MainPage;
