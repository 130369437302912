import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';

const VendorsAdapter = createEntityAdapter({});

const initialState = VendorsAdapter.getInitialState();

export const VendorsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVendors: builder.query({
      query: () => ({
        url: '/Vendor/all-Vendors',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedVendors = responseData.map((Vendor) => {
          Vendor.id = Vendor._id;
          return Vendor;
        });
        return VendorsAdapter.setAll(initialState, loadedVendors);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: 'Vendor', id: 'LIST' },
            ...result.ids.map((id) => ({ type: 'Vendor', id })),
          ];
        } else return [{ type: 'Vendor', id: 'LIST' }];
      },
    }),
    getVendorOrders: builder.query({
      query: () => ({
        url: '/vendor/get-vendor-orders',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: 'Vendor', id: 'LIST' },
            ...result.ids.map((id) => ({ type: 'Vendor', id })),
          ];
        } else return [{ type: 'Vendor', id: 'LIST' }];
      },
    }),
    addNewVendor: builder.mutation({
      query: (initialVendorData) => ({
        url: '/vendor/register',
        method: 'POST',
        body: {
          ...initialVendorData,
        },
      }),
      invalidatesTags: [{ type: 'Vendor', id: 'LIST' }],
    }),
    updateVendor: builder.mutation({
      query: (initialVendorData) => ({
        url: '/vendor/update-vendor',
        method: 'PATCH',
        body: {
          ...initialVendorData,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Vendor', id: arg.id }],
    }),
    deleteVendor: builder.mutation({
      query: ({ id }) => ({
        url: `/Vendors`,
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Vendor', id: arg.id }],
    }),
  }),
});

export const {
  useGetVendorsQuery,
  useAddNewVendorMutation,
  useUpdateVendorMutation,
  useDeleteVendorMutation,
  useGetVendorOrdersQuery,
} = VendorsApiSlice;

// returns the query result object
export const selectVendorsResult =
  VendorsApiSlice.endpoints.getVendors.select();
export const selectVendorsOrdersResult =
  VendorsApiSlice.endpoints.getVendorOrders.select();

// creates memoized selector
const selectVendorsData = createSelector(
  selectVendorsResult,
  (VendorsResult) => VendorsResult.data // normalized state object with ids & entities
);
const selectVendorOrdersData = createSelector(
  selectVendorsOrdersResult,
  (VendorsResult) => VendorsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllVendors,
  selectById: selectVendorById,
  selectIds: selectVendorIds,
  // Pass in a selector that returns the Vendors slice of state
} = VendorsAdapter.getSelectors(
  (state) => selectVendorsData(state) ?? initialState
);
export const {
  selectAll: selectAllVendorOrders,
  selectById: selectVendorOrdersById,
  selectIds: selectVendorOrderIds,
  // Pass in a selector that returns the Vendors slice of state
} = VendorsAdapter.getSelectors(
  (state) => selectVendorOrdersData(state) ?? initialState
);
