/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SpinerComponent from '../components/SpinerComponent';
import { IoIosClose } from 'react-icons/io';

const AddProductFormTwo = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [addImagsErr, setAddImgsErr] = useState(null);
  const [showSpina, setShowSpina] = useState(false);
  const [showImg, setShowImg] = useState(true);
  const vndor = localStorage.getItem('vendor');

  const prdId = localStorage.getItem('productKey')
    ? JSON.parse(localStorage.getItem('productKey'))
    : null;

  //console.log(`product id received: ${prdId}`);
  const navigate = useNavigate();

  useEffect(() => {
    if (vndor === null) {
      navigate('/');
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const prevImages = async (files) => {
    files.forEach((file) => {
      setSelectedImages((prevState) => [...prevState, file]);
    });
  };

  const uploadImageF = async (e) => {
    e.preventDefault();
    setShowSpina(true);
    try {
      const formData = new FormData();

      for (let i = 0; i < selectedImages.length; i++) {
        formData.append('images', selectedImages[i]);
      }

      if (selectedImages.length < 1) {
        throw Error('Please upload one image');
      }
      if (selectedImages.length > 1) {
        throw Error('Please upload ONLY ONE image');
      }

      const res = await fetch(
        `https://worknshup-vendor-backend-0ea3e2e25f6c.herokuapp.com/api/upload/${prdId}`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (!res.ok) {
        throw Error('Something went wrong img upload');
      }

      if (!prdId) {
        throw Error('Something went wrong with prdId!');
      }

      toast.success('Image added successfully!');
      navigate('/vendor-main-page');
      setTimeout(() => {
        setShowSpina(false);
      }, 2000);
    } catch (err) {
      setAddImgsErr(err.message);
      setTimeout(() => {
        setShowSpina(false);
      }, 2000);
    }
  };

  return (
    <>
      <form enctype="multipart/form-data" onSubmit={(e) => uploadImageF(e)}>
        <h3 className="mb-4 title">Add Product Images</h3>
        <div className="bg-white text-center border-1 p-5 mt-3">
          <Dropzone onDrop={(files) => prevImages(files)}>
            {({ getRootProps, getInputProps }) => (
              <div className="container">
                <div
                  {...getRootProps({
                    className: 'dropzone',
                    onDrop: (event) => event.stopPropagation(),
                  })}
                >
                  <input
                    {...getInputProps()}
                    accept="image/*"
                    type="file"
                    id="files"
                  />
                  <button
                    type="button"
                    className="btn border-3 btn-success rounded-3 my-1"
                  >
                    Add Product Picture
                  </button>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="d-flex align-items-center">
            {selectedImages.length > 0 &&
              showImg &&
              selectedImages.map((image, index) => (
                <div
                  className="img-prev"
                  style={{ width: '200px', height: '200px' }}
                >
                  <img
                    width={200}
                    height={200}
                    src={`${URL.createObjectURL(image)}`}
                    key={index}
                    alt="images"
                  />
                  <IoIosClose
                    className="clos-btn fs-3"
                    onClick={() => setShowImg(false)}
                  />
                </div>
              ))}
          </div>
        </div>
        <p style={{ color: 'red' }}>Add only one picture</p>
        {addImagsErr && <p style={{ color: 'red' }}>{addImagsErr}</p>}
        <button
          type="submit"
          className="btn btn-success border-0 rounded-3 my-5"
        >
          Add Picture
        </button>
        {showSpina && <SpinerComponent />}
      </form>
    </>
  );
};

export default AddProductFormTwo;
