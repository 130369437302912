import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import SpinerComponent from '../components/SpinerComponent';
import { useDeleteProductMutation } from '../features/products/productsApiSlice';

const CustomModal = ({ showw, id }) => {
  const navigate = useNavigate();
  const [showSpina, setShowSpina] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteProduct, { isLoading, isSuccess, error }] =
    useDeleteProductMutation();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (showw) {
      setShow(false);
    }
  }, [showw]);

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }
    if (isSuccess) {
      handleClose();
      setShowSpina(false);
      //refresh();
      toast.success('Product deleted!');
      navigate('/vendor-main-page');
    }
    if (error) {
      handleClose();
      setShowSpina(false);
      toast.error('Something went wrong!');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, error]);

  const handleDelete = async () => {
    try {
      await deleteProduct({ id });
    } catch (err) {}

    handleClose();
  };

  return (
    <>
      <button
        onClick={handleShow}
        className="btn btn-success border-0 rounded-3 mt-5"
        style={{ backgroundColor: 'red' }}
      >
        Delete
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>DELETE THIS PRODUCT?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are You Sure You Want To Delete This Product?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: 'red' }}
            onClick={() => handleDelete()}
            variant="primary"
          >
            delete
          </Button>
        </Modal.Footer>
      </Modal>
      {showSpina && <SpinerComponent />}
    </>
  );
};

export default CustomModal;
