/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Meta from '../components/Meta';
import { Navbar } from '../components/Navbar';
import Footer from '../components/Footer';
import { useGetVendorOrdersQuery } from '../features/vendors/vendorsApiSlice';

const ApproveOrderPage = () => {
  const { id } = useParams();

  let order;

  let content;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { data, isSuccess, isLoading, error } = useGetVendorOrdersQuery(
    'orderList',
    {
      pollingInterval: 15000,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  order = data?.vendorOrder?.find((odi) => {
    return odi?._id === id;
  });

  if (isSuccess) {
    content = (
      <>
        <Meta title="vendor-order-page" />
        <Navbar />
        <section className="home-wrapper container-fluid py-1">
          <div className="row">
            <div className="col-12 col-md-12 col-sm-12">
              <form
                className="d-flex  flex-column align-items-center"
                id="form"
                onSubmit={(e) => e.preventDefault()}
              >
                <h3 className="mb-4 title">Order Details</h3>

                <div>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Order Number:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.orderNumber}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Name:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.user?.firstname} {order?.user?.lastname}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    phone no:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.user?.mobile}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    company:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.user?.company}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    city:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.deliveryDetails?.city}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    address:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.deliveryDetails?.city}{' '}
                    </span>
                  </p>

                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Item:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.product?.title}{' '}
                    </span>
                  </p>

                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Order Status:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.orderStatus}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Payment Plan:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.paymentPlan}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Payment duration:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.paymentDuration}{' '}
                    </span>
                  </p>

                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    price:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      GHS {order?.totalPrice}{' '}
                    </span>
                  </p>

                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Initial Payment:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      GHS {Math.round(order?.initialPayment)}{' '}
                    </span>
                  </p>
                  {order?.secondPayment > 0 && (
                    <p
                      style={{
                        color: 'black',
                      }}
                    >
                      Second Payment:{' '}
                      <span style={{ fontWeight: 'Bold' }}>
                        GHS {Math.round(order?.secondPayment)}{' '}
                      </span>
                    </p>
                  )}
                  {order?.thirdPayment > 0 && (
                    <p
                      style={{
                        color: 'black',
                      }}
                    >
                      Third Payment:{' '}
                      <span style={{ fontWeight: 'Bold' }}>
                        GHS {Math.round(order?.thirdPayment)}{' '}
                      </span>
                    </p>
                  )}
                  {order?.fourthPayment > 0 && (
                    <p
                      style={{
                        color: 'black',
                      }}
                    >
                      Fourth Payment:{' '}
                      <span style={{ fontWeight: 'Bold' }}>
                        GHS {Math.round(order?.fourthPayment)}{' '}
                      </span>
                    </p>
                  )}
                  {order?.fifthPayment > 0 && (
                    <p
                      style={{
                        color: 'black',
                      }}
                    >
                      Fifth Payment:{' '}
                      <span style={{ fontWeight: 'Bold' }}>
                        GHS {Math.round(order?.fifthPayment)}{' '}
                      </span>
                    </p>
                  )}
                  {order?.sixthPayment > 0 && (
                    <p
                      style={{
                        color: 'black',
                      }}
                    >
                      Sixth Payment:{' '}
                      <span style={{ fontWeight: 'Bold' }}>
                        GHS {Math.round(order?.sixthPayment)}{' '}
                      </span>
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }

  if (isLoading) {
    content = (
      <>
        <Meta title="vendor-order-page" />
        <Navbar />
        <p>Loading...</p>
        <Footer />
      </>
    );
  }

  if (error) {
    content = (
      <>
        <Meta title="vendor-order-page" />
        <Navbar />
        <p>Something went wrong...</p>
        <Footer />
      </>
    );
  }

  return content;
};

export default ApproveOrderPage;
