/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLogoutMutation } from '../features/auth/authApiSlice';
import SpinerComponent from '../components/SpinerComponent';
import { toast } from 'react-toastify';
import './Navbar.css';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useGetVendorsQuery } from '../features/vendors/vendorsApiSlice';
import { AiOutlineClose } from 'react-icons/ai';

export const Navbar = ({
  handleShowVendorAccDetails,
  handleShowAllPrdtcs,
  handleShowNewPrdtcs,
  handleShowVendorOrders,
}) => {
  const [Logout, { isLoading, isSuccess, error }] = useLogoutMutation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showSpina, setShowSpina] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const vid = localStorage.getItem('vid');

  const { vendor } = useGetVendorsQuery('vendorsList', {
    selectFromResult: ({ data }) => ({
      vendor: data?.entities[vid],
    }),
  });

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }
    if (isSuccess) {
      setShowSpina(false);
      navigate('/');
    }

    if (error) {
      setShowSpina(false);
      toast.error('Something went wrong!');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, error]);

  let collapsedNav = null;

  if (pathname === '/vendor-main-page') {
    collapsedNav = (
      <>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          {!menuOpen && (
            <>
              <span></span>
              <span></span>
              <span></span>
            </>
          )}

          {menuOpen && (
            <div style={{ marginRight: '4rem' }}>
              <AiOutlineClose size={50} color="black" />
            </div>
          )}
        </div>

        <ul className={menuOpen ? 'open' : ''}>
          <li>
            {vendor && (
              <h4
                style={{
                  color: 'black',
                  marginRight: '2rem',
                  marginTop: '0.5rem',
                  textOverflow: 'ellipsis',
                }}
              >
                Welcome, {vendor?.BusinessName}!
              </h4>
            )}
            <NavLink
              onClick={() => {
                handleShowNewPrdtcs();
                setMenuOpen(false);
              }}
              className="dontshow"
            >
              Add New Products
            </NavLink>
            <NavLink
              onClick={() => {
                handleShowAllPrdtcs();
                setMenuOpen(false);
              }}
              className="dontshow"
            >
              All Products
            </NavLink>
            <NavLink
              onClick={() => {
                handleShowVendorOrders();
                setMenuOpen(false);
              }}
              className="dontshow"
            >
              Orders
            </NavLink>
            <NavLink
              onClick={() => {
                handleShowVendorAccDetails();
                setMenuOpen(false);
              }}
              className="dontshow"
            >
              Profile
            </NavLink>
            <NavLink
              onClick={() => {
                Logout();
                setMenuOpen(false);
              }}
            >
              Logout
            </NavLink>
          </li>
        </ul>
      </>
    );
  }

  return (
    <nav>
      <Link
        to="/vendor-main-page"
        className="title"
        style={{ height: '1.8em', width: '8.5em' }}
      >
        <img
          src="/images/wnshupm.png"
          style={{
            objectFit: 'fill',
            height: '100%',
            width: '100%',
          }}
          alt="brand"
        />
      </Link>
      {collapsedNav}
      {showSpina && <SpinerComponent />}
    </nav>
  );
};
