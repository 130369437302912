import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './app/store';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
//import { disableReactDevTools } from '@fvilers/disable-react-devtools';

//if (ProcessingInstruction.env.NODE_ENV === 'production') disableReactDevTools();

Sentry.init({
  dsn: 'https://8fec2f5f6c5535afc0528df800b1dd61@o4506496556072960.ingest.sentry.io/4506498524184576',
  integrations: [new BrowserTracing()],

  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
