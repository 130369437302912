import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CustomInputy from '../components/CustomInputTwo';
import { useNavigate } from 'react-router-dom';
import SpinerComponent from '../components/SpinerComponent';
import 'react-quill/dist/quill.snow.css';
import { Select, Input } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TwoMonthsPlan from '../components/TwoMonthsPlan';
import PayDayPlan from '../components/PayDayPlan';
import ThreeMonthsPlan from '../components/ThreeMonthsPlan';
import { useAddNewProductMutation } from '../features/products/productsApiSlice';
import { useGetVendorsQuery } from '../features/vendors/vendorsApiSlice';
import SixMonthsPlan from './SixMonthsPlan';

const { TextArea } = Input;
let schema = Yup.object().shape({
  title: Yup.string().required('title is required'),
  description: Yup.string().required('description is required'),
  price: Yup.number().required('Price is required'),
  deliveryPrice: Yup.number().required('Price is required'),
  category: Yup.string().required('Category is required'),
  brand: Yup.string().required('Brand is required'),
  quantity: Yup.string().required('Quantity is required'),
});

const AddProductForm = ({ setShowFormOne, setShowFormTwo }) => {
  const vndor = localStorage.getItem('vendor');
  const vmail = localStorage.getItem('vdmail');
  const id = localStorage.getItem('vid');

  const [addNewProduct, { data, isSuccess, isLoading, error }] =
    useAddNewProductMutation();
  const { vendor } = useGetVendorsQuery('vendorsList', {
    selectFromResult: ({ data }) => ({
      vendor: data?.entities[id],
    }),
  });

  //const [addPrdErr, setAddPrdErr] = useState(null);
  const [showSpina, setShowSpina] = useState(false);
  //const [showPriceR, setShowPriceR] = useState(false);
  const [prodctPrice, setProdctPrice] = useState(0);
  const [prodctTitle, setProdctTitle] = useState('');
  const navigate = useNavigate();

  const sendEmailToAdmin = async () => {
    try {
      const BusinessName = vendor?.BusinessName;
      const email = vendor?.email;
      const title = prodctTitle;
      const price = prodctPrice;
      const formData = new FormData();
      formData.append('BusinessName', BusinessName);
      formData.append('email', email);
      formData.append('title', title);
      formData.append('price', price);
      const urlEncoded = new URLSearchParams(formData).toString();
      await fetch(
        'http://localhost:4056/api/vendor/send-email-product-created',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
          body: urlEncoded,
        }
      ).catch((err) => console.log(`Error occured: ${err}`));
    } catch (err) {
      console.log('error: email not sent!');
    }
  };

  useEffect(() => {
    if (vndor === null) {
      navigate('/');
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vndor === null) {
      navigate('/');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vndor]);

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }
    if (isSuccess) {
      sendEmailToAdmin();
      localStorage.setItem('productKey', JSON.stringify(data));
      setShowSpina(false);
      toast.success('Product created successfully!');
      setShowFormOne(false);
      setShowFormTwo(true);
    }

    if (error) {
      setShowSpina(false);
      toast.error('Something went wrong!');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isLoading, error]);

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      price: '',
      deliveryPrice: '',
      category: '',
      brand: '',
      quantity: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      setShowSpina(true);
      try {
        const {
          title,
          description,
          price,
          deliveryPrice,
          category,
          brand,
          quantity,
        } = values;
        let vendorEmail = vmail;
        let vendor = id;
        await addNewProduct({
          title,
          description,
          price,
          deliveryPrice,
          category,
          brand,
          quantity,
          vendorEmail,
          vendor,
        });
      } catch (err) {
        throw new Error(error);
      }
    },
  });

  return (
    <>
      <form id="form" onSubmit={formik.handleSubmit}>
        <h3 className="mb-4 title">Add Product</h3>

        <CustomInputy
          type="text"
          label="Enter Product Title"
          name="title"
          onCh={(e) => {
            setProdctTitle(e.target.value);
            formik.handleChange(e);
          }}
          onBl={formik.handleBlur('title')}
          val={formik.values.title}
        />
        <div className="error">
          {formik.touched.title && formik.errors.title}
        </div>
        <div className="mt-3">
          <TextArea
            placeholder="Enter short description"
            maxLength={100}
            name="description"
            onChange={formik.handleChange('description')}
            value={formik.values.description}
          />
        </div>
        <div className="error">
          {formik.touched.description && formik.errors.description}
        </div>
        <CustomInputy
          type="number"
          label="Enter Product Price"
          name="price"
          onCh={(e) => {
            //alert(e.target.value);
            setProdctPrice(e.target.value);
            //setShowPriceR(true);
            formik.handleChange(e);
          }}
          onBl={formik.handleBlur('price')}
          val={formik.values.price}
        />
        <div className="error">
          {formik.touched.price && formik.errors.price}
        </div>
        {prodctPrice <= 500 && <PayDayPlan prodctPrice={prodctPrice} />}
        {prodctPrice >= 400 && prodctPrice <= 1200 && (
          <TwoMonthsPlan prodctPrice={prodctPrice} />
        )}
        {prodctPrice > 1200 && prodctPrice <= 4000 && (
          <TwoMonthsPlan prodctPrice={prodctPrice} />
        )}
        {prodctPrice > 1200 && prodctPrice <= 4000 && (
          <ThreeMonthsPlan prodctPrice={prodctPrice} />
        )}
        {prodctPrice > 3000 && <SixMonthsPlan prodctPrice={prodctPrice} />}

        <CustomInputy
          type="number"
          label="Enter delivery Price"
          name="deliveryPrice"
          onCh={formik.handleChange('deliveryPrice')}
          onBl={formik.handleBlur('deliveryPrice')}
          val={formik.values.deliveryPrice}
        />
        <div className="error">
          {formik.touched.deliveryPrice && formik.errors.deliveryPrice}
        </div>
        <CustomInputy
          type="text"
          label="Enter Product Brand"
          name="brand"
          onCh={formik.handleChange('brand')}
          onBl={formik.handleBlur('brand')}
          val={formik.values.brand}
        />
        <div className="error">
          {formik.touched.brand && formik.errors.brand}
        </div>

        <Select
          className="w-100 mt-3 py-1"
          showSearch
          placeholder="Select Category"
          optionFilterProp="children"
          onChange={formik.handleChange('category')}
          // onSearch={onSearch}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: 'Phones and Tablets',
              label: 'Phones and Tablets',
            },
            {
              value: 'Computers and accessories',
              label: 'Computers and accessories',
            },
            {
              value: 'Fashion and wears',
              label: 'Fashion and wears',
            },
            {
              value: 'Home and Office',
              label: 'Home and Office',
            },
            {
              value: 'Electronic Appliances',
              label: 'Electronic Appliances',
            },
            {
              value: 'Food Market',
              label: 'Food Market',
            },
            {
              value: 'Beauty and Cosmetics',
              label: 'Beauty and Cosmetics',
            },
          ]}
        />
        <div className="error">
          {formik.touched.category && formik.errors.category}
        </div>

        <CustomInputy
          type="number"
          name="quantity"
          onCh={formik.handleChange('quantity')}
          onBl={formik.handleBlur('quantity')}
          val={formik.values.quantity}
          label="Enter Product Quantity"
          // className="mb-3"
        />
        <div className="error">
          {formik.touched.quantity && formik.errors.quantity}
        </div>

        <button
          type="submit"
          className="btn btn-success border-0 rounded-3 mt-5"
        >
          create Product
        </button>
        {showSpina && <SpinerComponent />}
        {/* {addPrdErr && <p style={{ color: 'red' }}>Something went wrong!</p>} */}
      </form>
    </>
  );
};

export default AddProductForm;
