/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import CustomInput from '../components/CustomInput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useLoginMutation } from '../features/auth/authApiSlice';
import { setCredentials } from '../features/auth/authSlice';
import SpinerComponent from '../components/SpinerComponent';

const loginSchema = yup.object({
  email: yup
    .string()
    .email('Email should be valid')
    .required('Email is required!'),

  password: yup.string().required('Password is required!'),
});
const LandingPage = () => {
  //const vendorr = JSON.parse(localStorage.getItem('vendor'));
  const [showSpina, setShowSpina] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [invalidDetails, setShowInvalidDetails] = useState(false);
  const [rejected, setShowRejected] = useState(false);
  const [approval, setShowApproval] = useState(false);
  let invalid = 'Invalid Email or Password!';
  let rejects = 'Vendor has been Blocked!';
  let approve = 'Vendor has Not Been Verified!';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, { data, isLoading, isSuccess, error }] = useLoginMutation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // if (vendorr) {
    //   navigate('/vendor-main-page');
    // }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }
    if (isSuccess) {
      setShowSpina(false);
      localStorage.setItem('vdmail', data?.email);
      localStorage.setItem('vid', data?.id);
      navigate('/vendor-main-page');
    }

    if (error) {
      setShowSpina(false);
      setShowLoginError(true);
      let errar = JSON.stringify(error);
      let pars = JSON.parse(errar);
      toast.error(`${pars?.data?.msg}`);

      if (pars) {
        if (pars?.data?.msg === 'Invalid Email or Password!') {
          setShowInvalidDetails(true);
          setShowRejected(false);
          setShowApproval(false);
          setShowLoginError(true);
        }
        if (pars?.data?.msg === 'Vendor has been Blocked!') {
          setShowRejected(true);
          setShowInvalidDetails(false);
          setShowApproval(false);
          setShowLoginError(true);
        }
        if (pars?.data?.msg === 'Vendor has Not Been Verified!') {
          setShowApproval(true);
          setShowInvalidDetails(false);
          setShowRejected(false);
          setShowLoginError(true);
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isLoading, error]);

  const formik = useFormik({
    initialValues: {
      email: '',

      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      const { email, password } = values;

      try {
        const data = await login({ email, password }).unwrap();
        dispatch(setCredentials(data));
      } catch (error) {
        throw new Error(error);
      }
    },
  });
  return (
    <>
      <div className="container-fluid p-0">
        <section
          id="home"
          className="d-flex p-3 flex-column align-items-center"
        >
          <h1>Customers buy more, with flexible payments.</h1>
          <p>
            Increase your sales by reaching Ghana's largest community of working
            class people.
          </p>
          <form
            onSubmit={formik.handleSubmit}
            className="d-flex flex-column gap-15"
            style={{ marginTop: '9rem' }}
          >
            <div>
              <CustomInput
                type="email"
                name="email"
                label="email"
                val={formik.values.email}
                onCh={formik.handleChange('email')}
                onBl={formik.handleBlur('email')}
              />
              <div className="error">
                {formik.touched.email && formik.errors.email}
              </div>
            </div>
            <div>
              <CustomInput
                type="password"
                name="password"
                label="password"
                val={formik.values.password}
                onCh={formik.handleChange('password')}
                onBl={formik.handleBlur('password')}
              />
              <div className="error">
                {formik.touched.password && formik.errors.password}
              </div>
            </div>
            <div>
              <Link
                style={{ textDecoration: 'none', fontSize: '0.8rem' }}
                className="text-white"
                to="/forgot-password"
              >
                forgot password?
              </Link>
            </div>
            {showLoginError && (
              <div>
                <p style={{ color: 'red' }}>
                  {invalidDetails && invalid}
                  {rejected && rejects}
                  {approval && approve}
                </p>
              </div>
            )}
            <div className="d-flex mt-3 justify-content-center align-items-center gap-15">
              <button className="buttonext" type="submit">
                Login
              </button>
            </div>

            <div className="d-flex flex-column text-white">
              <Link
                style={{ textDecoration: 'none', fontSize: '1.2rem' }}
                className="text-white pt-2"
                to="/signup-page-one"
              >
                New Business? Signup
              </Link>
            </div>

            {showSpina && <SpinerComponent />}
          </form>
        </section>
      </div>
    </>
  );
};

export default LandingPage;
