/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useGetVendorOrdersQuery } from '../features/vendors/vendorsApiSlice';
import { Link } from 'react-router-dom';

const VendorOrders = () => {
  const vndorid = localStorage.getItem('vdmail');

  const { data, isSuccess } = useGetVendorOrdersQuery('orderList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  let content = (
    <div>
      <div className="contact-inner-wrapper d-flex justify-content-between">
        <div>
          <h3 className="contact-title mb-4">Your Orders</h3>
        </div>
      </div>
    </div>
  );

  // let vnd = [];

  // data?.vendorOrder?.map((ide) => {
  //   if (ide?.product?.vendorEmail === 'nwekeug4307@yahoo.com') {
  //     vnd.push(ide);
  //   }
  //   return vnd;
  // });

  if (isSuccess) {
    content = (
      <div>
        <div className="container-fluid contact-inner-wrapper d-flex flex-column justify-content-between">
          <div>
            <h3 className="contact-title mb-4">Your Orders</h3>
          </div>
          <div className="d-flex flex-column">
            <div className="row  w-100">
              {data?.vendorOrder?.map((oda) => {
                if (oda?.product?.vendorEmail === vndorid) {
                  if (oda?.orderStatus === 'APPROVED') {
                    return (
                      <div className="col-12">
                        <Link
                          to={`/order-details/${oda?._id}`}
                          className="d-flex align-items-end"
                          style={{
                            borderRadius: '0.5rem',
                            textDecoration: 'none',
                            backgroundColor: 'white',
                            marginBottom: '0.5rem',
                            padding: '0.5rem 0.5rem 0.5rem 0.5rem',
                          }}
                        >
                          <div key={oda} className="row w-100">
                            <div className="col col-sm col-md">
                              {oda?.orderNumber}
                            </div>
                            <div className="col col-sm col-md">
                              {oda?.product?.title}
                            </div>

                            <div className="col col-sm col-md">
                              {oda?.user?.firstname}
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  }
                }
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default VendorOrders;
