import React, { useEffect } from 'react';
import Meta from '../components/Meta';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

const AwaitingApprovalPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Meta title="Sign-Up-Two" />

      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid py-1 ">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <h2 className="pb-3">Thank You For Signing Up!</h2>
              <p>
                Please wait for your registration to be Verified withing 24hrs
              </p>
              <Link to={'/'}>Go to home</Link>
            </div>
          </div>
          <br />
          <br />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default AwaitingApprovalPage;
