import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials } from '../../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://worknshup-vendor-backend-0ea3e2e25f6c.herokuapp.com/api',
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = JSON.parse(localStorage.getItem('vendor_tkn'));
    //getState().auth.token;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // console.log(args) // request url, method, body
  // console.log(api) // signal, dispatch, getState()
  // console.log(extraOptions) //custom like {shout: true}

  let result = await baseQuery(args, api, extraOptions);

  // If you want, handle other status codes, too
  if (result?.error?.status === 403) {
    console.log('sending refresh token');

    // send refresh token to get new access token
    const refreshResult = await baseQuery('/vendor/refresh', api, extraOptions);

    if (refreshResult?.data) {
      // store the new token
      api.dispatch(setCredentials({ ...refreshResult.data }));

      // retry original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      if (
        refreshResult?.error?.status === 403 ||
        refreshResult?.error?.status === 500
      ) {
        localStorage.setItem('vendor_tkn', null);
        localStorage.setItem('vendor', null);
        localStorage.setItem('vdmail', null);
        localStorage.setItem('vid', null);
        refreshResult.error.data.message = 'Your login has expired.';
      }
      return refreshResult;
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Products', 'Orders', 'User'],
  endpoints: (builder) => ({}),
});
