import React from 'react';

const ThreeMonthsPlan = ({ prodctPrice }) => {
  return (
    <div>
      <div>
        <h6>3 Months plan</h6>
        <div className="d-flex">
          <p>Initial Payment:</p>
          <p>GHS {Math.round(prodctPrice * 0.4)}</p>
        </div>
        <div className="d-flex">
          <p>Next Payment:</p>
          <p>GHS {Math.round(prodctPrice * 0.3)}</p>
        </div>
        <div className="d-flex">
          <p>Next Payment:</p>
          <p>GHS {Math.round(prodctPrice * 0.3)}</p>
        </div>
      </div>
    </div>
  );
};

export default ThreeMonthsPlan;
