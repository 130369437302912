/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useGetProductsQuery } from '../features/products/productsApiSlice';
import CustomInputy from '../components/CustomInputTwo';
import { useParams } from 'react-router-dom';
import { Select, Input } from 'antd';
import SpinerComponent from '../components/SpinerComponent';
import { useNavigate } from 'react-router-dom';
import Meta from '../components/Meta';
import { Navbar } from '../components/Navbar';
import { useUpdateProductMutation } from '../features/products/productsApiSlice';
import Footer from '../components/Footer';
import { toast } from 'react-toastify';
import CustomModal from '../components/CustomModal';

const { TextArea } = Input;
const EditProductPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const vndor = JSON.parse(localStorage.getItem('vendor'));
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [deliveryPrice, setdPrice] = useState('');
  const [category, setCategory] = useState('');
  const [brand, setBrand] = useState('');
  const [quantity, setQuantity] = useState('');
  // eslint-disable-next-line
  const [showw, setShoww] = useState(false);

  const [showSpina, setShowSpina] = useState(false);

  const [
    updateProduct,
    { isLoading: updateLoading, isSuccess: updateSuccess, error: updateError },
  ] = useUpdateProductMutation();

  const { product, isSuccess } = useGetProductsQuery('productsList', {
    selectFromResult: ({ data, isLoading, isSuccess }) => ({
      product: data?.entities[id],
      isLoading,
      isSuccess,
    }),
  });

  useEffect(() => {
    if (vndor === null) {
      navigate('/');
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setTitle(product?.title);
      setDescription(product?.description);
      setPrice(product?.price);
      setdPrice(product?.deliveryPrice);
      setCategory(product?.category);
      setBrand(product?.brand);
      setQuantity(product?.quantity);
    }
  }, [
    isSuccess,
    product?.title,
    product?.description,
    product?.price,
    product?.deliveryPrice,
    product?.category,
    product?.brand,
    product?.quantity,
  ]);

  useEffect(() => {
    if (updateLoading) {
      setShowSpina(true);
    }

    if (updateError) {
      setShowSpina(false);
      toast.error('Something went wrong');
    }

    if (updateSuccess) {
      setShowSpina(false);
      setTimeout(() => {
        setShowSpina(false);
      }, 2000);
      navigate('/vendor-main-page');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLoading, updateSuccess, updateError]);

  const handleEdit = async () => {
    try {
      await updateProduct({
        title,
        description,
        price,
        deliveryPrice,
        category,
        brand,
        quantity,
        id,
      });
    } catch (error) {
      setTimeout(() => {
        setShowSpina(false);
      }, 2000);
    }
  };

  const handleChange = (value) => {
    setCategory(value);
  };

  return (
    <>
      <Meta title="Edit-product-page" />
      <Navbar />
      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid py-1 ">
          <div className="row">
            <div className="col-12">
              <form
                className="d-flex  flex-column align-items-center"
                id="form"
                onSubmit={(e) => e.preventDefault()}
              >
                <h3 className="mb-4 title">Edit Product</h3>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                  >
                    Title
                  </p>
                  <CustomInputy
                    type="text"
                    label="Enter Product Title"
                    name="title"
                    onCh={(e) => setTitle(e.target.value)}
                    val={title}
                  />
                </div>

                <div className="mt-3">
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    description
                  </p>
                  <TextArea
                    className="select-txt-area"
                    placeholder="Enter short description"
                    maxLength={100}
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    price
                  </p>
                  <CustomInputy
                    type="number"
                    label="Enter Product Price"
                    name="price"
                    onCh={(e) => setPrice(e.target.value)}
                    val={price}
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    delivery price
                  </p>

                  <CustomInputy
                    type="number"
                    label="Enter delivery Price"
                    name="price"
                    onCh={(e) => setdPrice(e.target.value)}
                    val={deliveryPrice}
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    brand
                  </p>

                  <CustomInputy
                    type="text"
                    label="Enter Product Brand"
                    name="brand"
                    onCh={(e) => setBrand(e.target.value)}
                    val={brand}
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    choose category
                  </p>

                  <Select
                    className="select-drop-down"
                    showSearch
                    value={category}
                    placeholder="Select Category"
                    optionFilterProp="children"
                    onChange={handleChange}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: 'Phones and Tablets',
                        label: 'Phones and Tablets',
                      },
                      {
                        value: 'Computers and accessories',
                        label: 'Computers and accessories',
                      },
                      {
                        value: 'Fashion and wears',
                        label: 'Fashion and wears',
                      },
                      {
                        value: 'Home and Office',
                        label: 'Home and Office',
                      },
                      {
                        value: 'Electronic Appliances',
                        label: 'Electronic Appliances',
                      },
                      {
                        value: 'Food Market',
                        label: 'Food Market',
                      },
                      {
                        value: 'Beauty and Cosmetics',
                        label: 'Beauty and Cosmetics',
                      },
                    ]}
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    quantity
                  </p>
                  <CustomInputy
                    type="number"
                    name="quantity"
                    onCh={(e) => setQuantity(e.target.value)}
                    val={quantity}
                    label="Enter Product Quantity"
                    className="mb-3"
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <button
                    onClick={() => handleEdit()}
                    className="btn btn-success border-0 rounded-3 mt-5"
                    style={{ marginRight: '5rem' }}
                  >
                    Update
                  </button>
                  <CustomModal showw={showw} id={id} />
                </div>

                {showSpina && <SpinerComponent />}
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default EditProductPage;
